<template>
  <div class="fault">
    <section class="main">
      <div class="top">
        <font-awesome-icon
          icon="fa-regular fa-face-sad-tear"
          size="8x"
        ></font-awesome-icon>
        <span class="title">網頁發生錯誤</span>
        <span>請回首頁再試一次</span>
      </div>
      <a @click.prevent="goBackToHome">
        <font-awesome-icon
          icon="fa-solid fa-house"
          size="2x"
        ></font-awesome-icon>
      </a>
    </section>
  </div>
</template>
<script>
export default {
  name: 'Error',
  data() {
    return {};
  },
  methods: {
    goBackToHome() {
      this.$router
        .push({ path: '/home', query: this.$route.query })
        .catch(err => console.log(err));
    },
  },
};
</script>

<style src="@/assets/css/qcard.css"></style>
